.mes {
  margin-left: 40px;
  margin-right: 40px;
}

.calendar {
  margin: 40px 0;
  padding: 0 20px;
}

.day {
  min-height: 100px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hour {
  text-align: center;
  margin: 4px 0;
  font-size: 1rem;
}

.empty {
  color: var(--color-gray);
  text-align: center;
  font-style: italic;
  font-size: 14px;
}

.buttons {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}