.mes {
  margin-left: 40px;
  margin-right: 40px;
}

.calendar {
  padding: 10px;
  flex-grow: 1;
  margin-bottom: 30px;
}

.calendarMonth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 .5rem 0;
}

.calendarEvent {
  border: 1px solid black;
  padding: 5px;
  margin: 8px 0;
  border-radius: 3px;
}

.calendarEvent[data-status="dimmed"] {
  filter: blur(2px);
  opacity: .5;
}

.calendarEventRow {
  margin: 0;
  font-size: .65rem;
  line-height: .8rem;
  position: relative;
  display: flex;
  align-items: center;
}

.calendarEventRow:first-of-type {
  margin-bottom: 4px;
}

.calendarEventRow i {
  font-size: 1.25rem;
  margin-right: 7px;
}