.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-left: 40px;
}

.year {
  margin-left: 40px;
}

.error {
  color: var(--color-primary-red);
}

.success {
  color: var(--color-primary-green);
}