.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 10px;
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 20px;
}