.main {
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}