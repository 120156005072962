.actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.fecha {
  margin-right: 30px;
}

.download {
  margin-right: 30px;
}
