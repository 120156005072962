.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-left: 40px;
}

.mes {
  margin-left: 40px;
}

.modificacion {
  font-weight: bold;
}

.modificacion[data-status="positive"] {
  color: var(--color-primary-green);
}

.modificacion[data-status="negative"] {
  color: var(--color-primary-red);
}