.intro {
  margin-bottom: 1rem;
}

.centro {
  font-size: 1.25rem;
  border-bottom: 1px solid var(--color-gray);
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.label {
  margin-left: .5rem;
}

.content {
  padding: 0 .6rem;
}

.fecha {
  margin: 1.25rem 0 0.2rem;
}

.rows {
  padding: 0 .8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.row {
  flex-basis: 25%;
}