.main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.logo {
  margin-bottom: 30px;
  width: 300px;
  max-width: 90%;
}