.title {
  color: var(--color-dark);
  font-size: 1.15rem;
  letter-spacing: .2px;
  font-weight: 300;
  margin: 20px 0;
  padding: 0 40px;
}

.header {
  margin: 20px 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alumnos {
  flex-basis: 40%;
}

.contratacion {
  min-width: 300px;
  flex-basis: 40%;
}

.details {
  margin: 50px 0;
  padding: 0 20px;
}

.section {
  margin-top: 60px;
}

.sectionTitle {
  color: var(--color-dark);
  font-size: 1.05rem;
  letter-spacing: .2px;
  font-weight: 700;
}

.link {
  color: var(--color-primary-green);
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.removeBanner {
  margin: 2rem 3rem 0;
}

.removeLink {
  margin-left: 10px;
}

.placeholder {
  color: var(--color-gray);
  font-style: italic;
  margin: 0;
}

