.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-left: 40px;
}

.year {
  margin-left: 40px;
}

.balance[data-status="positive"] {
  color: var(--color-primary-green);
  font-weight: bold;
}

.balance[data-status="negative"] {
  color: var(--color-primary-red);
  font-weight: bold;
}

.importe {
  color: var(--color-primary-orange);
}

.objetivo {
  cursor: pointer;
}

.objetivo:hover {
  text-decoration: underline;
}

.totalRow {
  background-color: rgb(var(--color-primary-green-light-rgb), .15);
}