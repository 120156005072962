.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.filters {
  margin: 1rem;
}

.filterSummary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.filterTitle {
  font-size: 1rem;
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1.5rem 0;
}

.row:first-of-type {
  margin-top: 0;
}

.input {
  flex-basis: 33%;
  flex-grow: 1;
  margin: 0 20px;
}

.cerrado {
  color: var(--color-primary-orange);
  font-weight: bold;
}