.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-left: 40px;
}

.mes {
  margin-left: 40px;
}

.abierta {
  background-color: var(--color-primary-green);
  color: white;
  padding: 3px 5px;
  margin: 0 2px;
  border-radius: 4px;
}

.descuadre[data-status="positive"] {
  color: var(--color-primary-green);
  font-weight: bold;
}

.descuadre[data-status="negative"] {
  color: var(--color-primary-red);
  font-weight: bold;
}