.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.mes {
  margin-left: 40px;
}

.cerrado {
  color: var(--color-primary-orange);
  font-weight: bold;
}