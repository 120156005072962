.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.input {
  margin-bottom: 30px;
}

.warningBanner {
  margin: 0 0 2rem;
}