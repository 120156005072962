.tabs {
  margin-top: 20px;
}

.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.mes {
  margin-left: 40px;
}
