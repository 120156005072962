.main {
  display: flex;
  flex-direction: column;
}

.intro {
  margin-bottom: 1rem;
}

.rows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}