.icon[data-status='valid'] {
  color: var(--color-primary-green);
}

.icon[data-status='invalid'] {
  color: var(--color-primary-red);
}

.name{
  cursor: pointer;
}

.name:hover{
  color: var(--color-primary-green);
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 10px;
}