.direccion {
  margin: 0 0 5px;
}

.region {
  margin: 0;
}

.activo {
  background-color: var(--color-primary-green);
  color: white;
  padding: 3px 5px;
  margin: 0 2px;
  border-radius: 4px;
}