.icon[data-status='valid'] {
  color: var(--color-primary-green);
}

.icon[data-status='invalid'] {
  color: var(--color-primary-red);
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 20px;
}