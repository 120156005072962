.calendar {
  padding: 10px;
  flex-grow: 1;
  margin-bottom: 30px;
}

.calendarMonth {
  font-size: 1.2rem;
}

.monthSelection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 .5rem 0;
}

.evento {
  padding: 5px 5px 5px 8px;
  font-size: .8rem;
  margin: 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.evento i {
  margin-right: 8px;
  font-size: 1.2rem;
}

.eventoTitulo {
  margin: 0;
}

.evento[data-type='COMUNICADO'] {
  background-color: rgba(var(--color-primary-orange-rgb), 0.5);
}

.evento[data-type='ALERTA'] {
  background-color: rgba(var(--color-secondary-orange-rgb), 0.5);
}

.evento[data-type='AVISO'] {
  background-color: rgba(var(--color-secondary-yellow-rgb), 0.5);
}

.evento[data-type='NOTIFICACION'] {
  background-color: rgba(var(--color-primary-green-light-rgb), 0.5);
}

.evento[data-type='RECORDATORIO'] {
  background-color: rgba(var(--color-auxiliary-rgb), 0.5);
}


.evento[data-status="dimmed"] {
  filter: blur(2px);
  opacity: .5;
}

.createButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}