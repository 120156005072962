.link {
  color: var(--color-dark);
  text-decoration: none;
}

.link:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}


.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-right: 40px;
}

.mes {
  margin-left: 40px;
}

.cerrado {
  color: var(--color-primary-orange);
  font-weight: bold;
}

.iconCheck {
  color: var(--color-primary-green);
}

.iconClose {
  color: var(--color-primary-red);
}

.progress {
  font-weight: bold;
}

.progress[data-status="ko"] {
  color: var(--color-primary-red);
}

.progress[data-status="warning"] {
  color: var(--color-primary-orange);
}

.progress[data-status="ok"] {
  color: var(--color-primary-green);
}
