.actions {
  display: flex;
  align-items: flex-end;
}

.download {
  margin-left: 40px;
}

.year {
  margin-left: 40px;
}

.codigo {
  margin-left: 40px;
  min-width: 200px;
}