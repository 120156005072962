.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-dark);
  height: 50px;
}

.logo {
  margin-left: 20px;
  height: 30px;
}

.image {
  height: 100%; 
}

.actions {
  display: flex;
  align-items: center;
  color: white;
  margin-right: 20px;
}

.clock {
  margin-right: 20px;
}

.profile {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.button {
  padding: 6px;
  min-width: 40px;
  margin: 0 6px;
}